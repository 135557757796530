import { Routes } from '@angular/router';
// import { MetaService } from 'ng2-meta';

import { HomeRoutes } from './+home/index';
import { CategoryRoutes } from './+category/index';
import { PageRoutes } from './+page/index';
// import { SubmitResourceRoutes } from './+submit-resource/index';
import { ResourceRoutes } from './+resource/index';
// import { ResourceStackComponent } from './+resource-stack/resource-stack.component';

export const routes: Routes = [
  ...HomeRoutes,
  ...CategoryRoutes,
  ...PageRoutes,
  ...ResourceRoutes
  // ...SubmitResourceRoutes
];
